
//import Content from "./Content";
import Navigator from "./Navigator";
import Paperbase from "./Paperbase";


function App() {
 
  return (
    
    <div className="App">
      <header>
        
      </header>
    <Navigator />
      
      <Paperbase />
      
      
    </div>
  );
}

export default App;
